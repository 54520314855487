<template>
  <div class="card" :class="isRTLPage ? '' : 'left-align'" no-body v-if="show">
    <b-card-header header-tag="header" class="p-0" role="tab">
      <b-button
        block
        v-b-toggle="`collapsed-item-subtype`"
        variant="primary"
        class="btn-header-link"
      >
        {{caption}}
      </b-button>
    </b-card-header>

    <b-collapse :id="`collapsed-item-subtype`">
      <div v-for="(subType, idxSubType) in subTypeList" :key="idxSubType">
        <b-card-header header-tag="header" class="p-0" role="tab">
          <b-button
            block
            v-b-toggle="`collapsed-item-${idxSubType}`"
            variant="primary"
            class="btn-header-link"
          >
            {{subTypeName(subType)}}
          </b-button>
        </b-card-header>

        <b-collapse :id="`collapsed-item-${idxSubType}`">
          <b-card>
            <div class="footer">
              <ul class="footer_list">
                <li v-for="(item, j) in getItemListWithSubType(subType[0])" :key="j">
                  <a class="footer-item" :href="item[0]"> {{item[1]}}</a>
                </li>
              </ul>
            </div>
          </b-card>
        </b-collapse>
      </div>
    </b-collapse>

  </div>
</template>

<script>
import {
  BButton,
  BCollapse,
  VBToggle,
  BCard,
  BCardHeader,
} from 'bootstrap-vue';
import TemplateFooterTreeColumnMobile from './TemplateFooterTreeColumnMobile';

export default {
  name: 'footer-tree-column-mobile',
  mixins: [TemplateFooterTreeColumnMobile],
  components: {
    BButton,
    BCollapse,
    BCard,
    BCardHeader,
  },
  directives: {
    'b-toggle': VBToggle,
  },
};
</script>

<style scoped>
header button.btn {
  text-align: inherit;
}
.card .card-header .btn-header-link.collapsed:after {
  content: '\f107';
}
.card .card-header .btn-header-link:after {
  content: '\f106';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  float: left;
}
.card.left-align .card-header .btn-header-link:after{
  float: right;
}
.card.left-align {
  text-align: left;
}
.card {
  margin-bottom: 5px;
}
.card-body, .card .card-header .btn-header-link{
  background: #0161ab;
}
a.footer_item{
  color: #e9e9e9 !important;
  text-decoration: none !important;
  font-weight: 300;
  transition: 0.5s;
}
a {
  color: #007bff;
  text-decoration: none !important;
  background-color: transparent;
}
</style>
