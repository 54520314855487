import { mapGetters } from 'vuex';

export default {
  name: 'footer-tree-column-mobile',
  props: {
    itemList: {
      type: Array,
      default: () => [],
    },
    subTypeList: {
      type: Array,
      default: () => [],
    },
    caption: {
      type: String,
      default: null,
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      isRTLPage: 'GET_IS_RLT_PAGE',
    }),
  },
  mounted() {
    this.subTypeList.forEach(() => {
      this.collapsed.push(true);
    });
  },
  data() {
    return {
      collapsed: [],
    };
  },
  methods: {
    getItemListWithSubType(subtype) {
      return this.itemList.filter((item) => (item[2] === subtype));
    },
    subTypeName(item) {
      if (this.lang === 'en') {
        return item[1];
      } else {
        return item[2];
      }
    },
    expandSubType(idx) {
      const list = [];
      this.collapsed[idx] = !this.collapsed[idx];
      this.collapsed.forEach((item) => {
        list.push(item);
      });

      this.collapsed = list;
    },
    shouldCollapsed(inx) {
      if (inx === 0) return '';
      return this.collapsed[inx - 1] ? '' : ' expanded';
    },
  },
  created() {
  },
};
